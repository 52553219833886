// https://github.com/nuxt/framework/issues/6711

export enum SectionComponents {
  // Used on Index Model / Hero field
  SectionHeroRecord = 'SectionHero',
  SectionHero2Record = 'SectionHero2',
  SectionHero3Record = 'SectionHero3',
  SectionShopHeroRecord = 'SectionShopHero',
  SectionTitleRecord = 'SectionTitle',
  SectionThankYouRecord = 'SectionThankYou',

  // Used on Index Model / Blocks field
  SectionArticleListRecord = 'SectionArticles',
  SectionRelatedArticlesListRecord = 'SectionRelatedArticles',
  SectionBodyRecord = 'SectionBody',
  SectionContactFormRecord = 'SectionContactForm',
  SectionCtaRecord = 'SectionCta',
  SectionFeaturesListRecord = 'SectionFeatures',
  SectionImageGalleryRecord = 'SectionImageGallery',
  SectionLogosListRecord = 'SectionLogos',
  SectionMediaVerticalRecord = 'SectionMediaVertical',
  SectionMediaHorizontalRecord = 'SectionMediaHorizontal',
  SectionTestimonialsListRecord = 'SectionTestimonials',
  SectionVideoRecord = 'SectionVideo',
  SectionMediafullRecord = 'SectionMediaFull',
  SectionFaqRecord = 'SectionFaq',
  SectionProgrameRecord = 'SectionProgrameCta',
  SectionCatalogRecord = 'SectionCatalog',
  SectionProgramRecord = 'SectionPrograms',
  SectionPositionRecord = 'SectionPosition',
  SectionSegmentRecord = 'SectionSegments',
  SectionProductListRecord = 'SectionProductList',
  SectionStackRecord = 'SectionStacks',
  SectionMultipleRecord = 'SectionMultiple',
  SectionInquiryFormRecord = 'SectionInquiryForm',
  SectionShopProductListRecord = 'SectionShopProductList',
  SectionRelatedShopProductsListRecord = 'SectionRelatedShopProductsList'
}

export enum MenuBlocks {
  MenuCtaRecord = 'MenuCallToAction',
  MenuGroupRecord = 'MenuGroup'
}
